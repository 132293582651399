import * as PIXI from 'pixi.js';
import * as dat from 'dat.gui';
import { allAssets, explosions, wellUI, background } from './utils/allAssets';
import { initiateScene } from './utils/initiateScene';
import { makeParallelVisible } from './utils/makeParallelVisible';
import { interactionHandler } from './utils/interactionHandler';
import { loadResources, LOAD_TYPE } from './utils/loadResources';
import { addLayerToTunnel } from './utils/addLayerToTunnel';
import { updateTunnelLayerPositions } from './utils/updateTunnelLayerPositions';
import { domInteractions } from './utils/domInteractions';
import { triggerDisplacementFilter } from './utils/triggerDisplacementFilter';
import { setContentLanguages } from './utils/contentLanguages';

const app = new PIXI.Application({
	backgroundColor: 0x000000,
	resizeTo: window,
});

document.body.appendChild(app.view);

const urlParams = new URLSearchParams(window.location.search);
const queryLang = urlParams.get('lang');

const currentLanguage = (() => {
	switch (true) {
		case queryLang === 'nl':
			return 'nl';

		case queryLang === 'en':
			return 'en';

		case process.env.NODE_ENV === 'installation':
			return 'nl';

		default:
			return 'en';
	};
})();

const store = {
	app,
	allAssets,
	newScene: () => {},
	loaderFileNameInterval: null,
	currentLanguage: currentLanguage,
	autoChangeParallels: false,
	sceneIndex: 0,
	sceneZ: 0,
	datGUISceneFolder: null,
	wellContainer: new PIXI.Container(),
	tunnelContainer: new PIXI.Container(),
	parallelsContainer: new PIXI.Container(),
	hasStartedExperience: false,
	isInstallationRun: process.env.NODE_ENV === 'installation',
	showLoaderMinimumTime: 6000,
	showSubtitles: null,
	saveSettings: () => {
		window.prompt(
			`scene${store.curSceneIndex}`, `${JSON.stringify(store[`scene${store.curSceneIndex}`])}`
		);
	},
	loadSettings: () => {
		const input = JSON.parse(window.prompt(
			`scene${store.curSceneIndex}`, ''
		));

		input && Object.entries(input).forEach(([key, value]) => {
			store[`scene${store.curSceneIndex}`][key] = value;
		});
	},
	ticker: () => {},
	screenSize: {
		width: 0,
		height: 0
	},
	mouseXY: [0, 0],
	aggregateMouseVelocity: 0,
	isKeyDown: false,
	explosionSprites: [],
	tunnelLayersCounter: 0,
	tunnelLayerFarthestPos: 3,
	tunnelLayers: [],
	tunnelResourcesInScene: null,
	hasLoaderInTunnel: false,
	allParallelsInScene: [],
	activeParallelElement: null,
	parallelElement: null,
	curLoaderSprite: null,
	soundTrackVolume: 1,
	scene0: {
		maxElementsInView: 100,
		scaleCutoff: 2,
		triggerNextSceneTimeout: 30000,
		delayToShowParallelAfterLoader: 5000,
		fallAccelerator: 0,
		fallSpeed: 0.02,
		tickCounter: 0,
		tickSpeed: 0.01,
		mouseMultX: 10,
		mouseMultY: 10,
		displaceFarEndX: -0.3,
		displaceFarEndY: 8.9,
		displaceFarEndSinusX: 0,
		displaceFarEndCosinusY: 0,
		distance: 0.08,
		sinusoidalAmplitude: 0.2,
		sinusoidalOffset: 1,
		randomSelectEnvOnStart: true,
		randomRotateEnvOnStart: false,
		linearRotateEnvOnStartMult: 0.01,
		randomDisplaceEnvOnStart: 0,
		randomScaleEnvOnStart: 0,
		parallelIndex: 0,
		parallelScale: 2,
		playbackSpeed: 0.71,
		parallelX: 0,
		parallelY: 0,
		parallelMouseMultX: 1,
		parallelMouseMultY: 1,
		parallelScaleMouseMultY: 1,
	},
	scene1: {
		maxElementsInView: 100,
		scaleCutoff: 2,
		triggerNextSceneTimeout: 31600,
		delayToShowParallelAfterLoader: 4000,
		fallAccelerator: 0,
		fallSpeed: 0.006,
		tickCounter: 0,
		tickSpeed: 0.01,
		mouseMultX: 30,
		mouseMultY: 30,
		displaceFarEndX: 1.4,
		displaceFarEndY: 25,
		displaceFarEndSinusX: 0,
		displaceFarEndCosinusY: 0,
		distance: 0.09,
		sinusoidalAmplitude: -1,
		sinusoidalOffset: 1,
		randomSelectEnvOnStart: true,
		randomRotateEnvOnStart: false,
		linearRotateEnvOnStartMult: 0,
		randomDisplaceEnvOnStart: 0,
		randomScaleEnvOnStart: 0,
		parallelIndex: 0,
		parallelScale: 2,
		playbackSpeed: 0.6,
		parallelX: 0,
		parallelY: 0,
		parallelMouseMultX: 0,
		parallelMouseMultY: 0,
		parallelScaleMouseMultY: 1,
	},
	scene2: {
		maxElementsInView: 100,
		scaleCutoff: 3.5,
		triggerNextSceneTimeout: 31600,
		delayToShowParallelAfterLoader: 4000,
		fallAccelerator: 0,
		fallSpeed: 0.02,
		tickCounter: 0,
		tickSpeed: 0.005,
		mouseMultX: 100,
		mouseMultY: 10,
		displaceFarEndX: -4.9,
		displaceFarEndY: 4.3,
		displaceFarEndSinusX: 0,
		displaceFarEndCosinusY: 0,
		distance: 0.1,
		sinusoidalAmplitude: 0,
		sinusoidalOffset: 1,
		randomSelectEnvOnStart: false,
		randomRotateEnvOnStart: false,
		linearRotateEnvOnStartMult: 0,
		randomDisplaceEnvOnStart: 0,
		randomScaleEnvOnStart: 0,
		parallelIndex: 0,
		parallelScale: 2.5,
		playbackSpeed: 0.5,
		parallelX: 0,
		parallelY: 0,
		parallelMouseMultX: 200,
		parallelMouseMultY: 200,
		parallelScaleMouseMultY: 1,
	},
	scene3: {
		maxElementsInView: 100,
		scaleCutoff: 3.5,
		triggerNextSceneTimeout: 31600,
		delayToShowParallelAfterLoader: 3000,
		fallAccelerator: 0,
		fallSpeed: 0.02,
		tickCounter: 0,
		tickSpeed: 0.005,
		mouseMultX: 10,
		mouseMultY: 10,
		displaceFarEndX: -17.5,
		displaceFarEndY: -2.6,
		displaceFarEndSinusX: 0,
		displaceFarEndCosinusY: 0,
		distance: 0.1,
		sinusoidalAmplitude: 1,
		sinusoidalOffset: 1,
		randomSelectEnvOnStart: false,
		randomRotateEnvOnStart: false,
		linearRotateEnvOnStartMult: 0,
		randomDisplaceEnvOnStart: 0,
		randomScaleEnvOnStart: 0,
		parallelIndex: 0,
		parallelScale: 1.5,
		playbackSpeed: 0.6,
		parallelX: 0,
		parallelY: 0,
		parallelMouseMultX: 0,
		parallelMouseMultY: 0,
		parallelScaleMouseMultY: 1,
	},
	scene4: {
		maxElementsInView: 100,
		scaleCutoff: 2,
		triggerNextSceneTimeout: 31600,
		delayToShowParallelAfterLoader: 3000,
		fallAccelerator: 0,
		fallSpeed: 0.02,
		tickCounter: 0,
		tickSpeed: 0.005,
		mouseMultX: 10,
		mouseMultY: 10,
		displaceFarEndX: -3.8,
		displaceFarEndY: 11.7,
		displaceFarEndSinusX: 0,
		displaceFarEndCosinusY: 0,
		distance: 0.08,
		sinusoidalAmplitude: 0.44474,
		sinusoidalOffset: 0.17537000000000003,
		randomSelectEnvOnStart: false,
		randomRotateEnvOnStart: false,
		linearRotateEnvOnStartMult: 0.043000000000000003,
		randomDisplaceEnvOnStart: 0,
		randomScaleEnvOnStart: 0,
		parallelIndex: 0,
		parallelScale: 2,
		playbackSpeed: 0.1,
		parallelX: 0,
		parallelY: 0,
		parallelMouseMultX: 0,
		parallelMouseMultY: 0,
		parallelScaleMouseMultY: 1,
	},
	scene5: {
		maxElementsInView: 100,
		scaleCutoff: 2,
		triggerNextSceneTimeout: 31600,
		delayToShowParallelAfterLoader: 3000,
		fallAccelerator: 0,
		fallSpeed: 0.02,
		tickCounter: 0,
		tickSpeed: 0.005,
		mouseMultX: 10,
		mouseMultY: 10,
		displaceFarEndX: 0.8,
		displaceFarEndY: 14,
		displaceFarEndSinusX: 2.46,
		displaceFarEndCosinusY: 0,
		distance: 0.08,
		sinusoidalAmplitude: 1,
		sinusoidalOffset: 1,
		randomSelectEnvOnStart: false,
		randomRotateEnvOnStart: false,
		linearRotateEnvOnStartMult: 0,
		randomDisplaceEnvOnStart: 0,
		randomScaleEnvOnStart: 0,
		parallelIndex: 5,
		parallelScale: 1.6,
		playbackSpeed: 0.6,
		parallelX: 0,
		parallelY: 0,
		parallelMouseMultX: 0,
		parallelMouseMultY: 0,
		parallelScaleMouseMultY: 1,
	},
	scene6: {
		maxElementsInView: 100,
		scaleCutoff: 2,
		triggerNextSceneTimeout: 31600,
		delayToShowParallelAfterLoader: 3000,
		fallAccelerator: 0.008834425976389917,
		fallSpeed: 0.004816995673335057,
		tickCounter: 105.36129999999582,
		tickSpeed: 0.007500000000000001,
		mouseMultX: 10,
		mouseMultY: 10,
		displaceFarEndX: 0.8,
		displaceFarEndY: 14,
		displaceFarEndSinusX: 2.46,
		displaceFarEndCosinusY: 0,
		distance: 0.107,
		sinusoidalAmplitude: 0.57942,
		sinusoidalOffset: 1,
		randomSelectEnvOnStart: false,
		randomRotateEnvOnStart: true,
		linearRotateEnvOnStartMult: 0.076,
		randomDisplaceEnvOnStart: 0,
		randomScaleEnvOnStart: 0,
		parallelIndex: 0,
		parallelScale: 2,
		playbackSpeed: 1,
		parallelX: 0,
		parallelY: 0,
		parallelMouseMultX: 0,
		parallelMouseMultY: 0,
		parallelScaleMouseMultY: 1,
	},
};

const newParallel = () => {
	makeParallelVisible(store);
};

store.newScene = process.env.NODE_ENV === 'development' ? (shouldReset) => {
	if (store.datGUISceneFolder) {
		gui.removeFolder(store.datGUISceneFolder);
	}

	const curScene = store[`scene${store.sceneIndex}`];
	store.datGUISceneFolder = gui.addFolder('Current options');
	store.datGUISceneFolder.open();

	const runtimeConfigFolder = store.datGUISceneFolder.addFolder('Scene running');
	runtimeConfigFolder.open();
	runtimeConfigFolder.add(curScene, 'maxElementsInView', 1, 250, 1);
	runtimeConfigFolder.add(curScene, 'scaleCutoff', 1, 3.5, 0.1);
	runtimeConfigFolder.add(curScene, 'fallSpeed', 0, 0.1, 0.00001);
	runtimeConfigFolder.add(curScene, 'tickSpeed', 0, 0.05, 0.0001);
	runtimeConfigFolder.add(curScene, 'mouseMultX', -100, 100, 0.01);
	runtimeConfigFolder.add(curScene, 'mouseMultY', -100, 100, 0.01);
	runtimeConfigFolder.add(curScene, 'sinusoidalAmplitude', -1, 1, 0.00001);
	runtimeConfigFolder.add(curScene, 'sinusoidalOffset', -1, 1, 0.00001);
	runtimeConfigFolder.add(curScene, 'displaceFarEndX', -25, 25, 0.1);
	runtimeConfigFolder.add(curScene, 'displaceFarEndY', -25, 25, 0.1);
	runtimeConfigFolder.add(curScene, 'displaceFarEndSinusX', -5, 5, 0.01);
	runtimeConfigFolder.add(curScene, 'displaceFarEndCosinusY', -5, 5, 0.01);

	const onMountConfigFolder = store.datGUISceneFolder.addFolder('Scene on mount');
	onMountConfigFolder.open();
	onMountConfigFolder.add(curScene, 'triggerNextSceneTimeout', 3000, 50000, 1).onChange(() => store.newScene(true));
	onMountConfigFolder.add(curScene, 'delayToShowParallelAfterLoader', 1000, 3000, 1).onChange(() => store.newScene(true));
	onMountConfigFolder.add(curScene, 'distance', 0.01, 2, 0.001).onChange(() => store.newScene(true));
	onMountConfigFolder.add(curScene, 'randomSelectEnvOnStart').onChange(() => store.newScene(true));
	onMountConfigFolder.add(curScene, 'randomRotateEnvOnStart').onChange(() => store.newScene(true));
	onMountConfigFolder.add(curScene, 'linearRotateEnvOnStartMult', -0.5, 0.5, 0.001).onChange(() => store.newScene(true));
	onMountConfigFolder.add(curScene, 'randomDisplaceEnvOnStart', -400, 400, 0.1).onChange(() => store.newScene(true));
	onMountConfigFolder.add(curScene, 'randomScaleEnvOnStart', -1, 2, 0.001).onChange(() => store.newScene(true));

	const parallelConfigFolder = store.datGUISceneFolder.addFolder('Parallels');
	parallelConfigFolder.open();
	parallelConfigFolder.add(curScene, 'parallelIndex', 0, 10, 1).onChange(() => newParallel()).listen();
	parallelConfigFolder.add(curScene, 'parallelScale', 0, 2, 0.01).onChange(() => newParallel());
	parallelConfigFolder.add(curScene, 'playbackSpeed', 0, 2, 0.01).onChange(() => newParallel());
	parallelConfigFolder.add(curScene, 'parallelX', -300, 300, 1);
	parallelConfigFolder.add(curScene, 'parallelY', -300, 300, 1);
	parallelConfigFolder.add(curScene, 'parallelMouseMultX', -200, 200, 0.1);
	parallelConfigFolder.add(curScene, 'parallelMouseMultY', -200, 200, 0.1);
	parallelConfigFolder.add(curScene, 'parallelScaleMouseMultY', -1000, 1000, 0.1);

	initiateScene(store, shouldReset);
} : (shouldReset) => {
	initiateScene(store, shouldReset);
};

store.screenSize = {
	width: store.app.screen.width,
	height: store.app.screen.height,
};

document.getElementsByTagName('body')[0].style.setProperty('--app-height', `${store.screenSize.height}px`);

store.app.renderer.on('resize', (width, height) => {
	store.screenSize.width = width;
	store.screenSize.height = height;
	updateTunnelLayerPositions(store);

	document.getElementsByTagName('body')[0].style.setProperty('--app-height', `${height}px`);
});

// Harshly force page reflow after orientation change
window.addEventListener('orientationchange', () => {
	document.getElementsByTagName('body')[0].classList.add('is-gone');

	setTimeout(() => {
		document.getElementsByTagName('body')[0].classList.remove('is-gone');
	}, 20);
});

const gui = process.env.NODE_ENV === 'development' ? new dat.GUI() : null;

if (process.env.NODE_ENV === 'development') {
	gui.add(store, 'saveSettings');
	gui.add(store, 'loadSettings');
	gui.add(store, 'soundTrackVolume', 0, 1).onChange(() => store.newScene(true));
	gui.add(store, 'sceneIndex', 0, 5, 1).onChange(() => store.newScene(true)).listen();
}

loadResources(store, wellUI, LOAD_TYPE.well, (_, loadedResources) => {
	store.newScene(true);
	interactionHandler(store, true);

	[...Array(5)].map(() => {
		const wellSprite = new PIXI.Sprite(Object.values(loadedResources)[0].texture);
		addLayerToTunnel(store, wellSprite, true);
	});

	store.tunnelLayerFarthestPos -= 0.4;

	triggerDisplacementFilter(store, store.tunnelContainer);
});

loadResources(store, explosions, LOAD_TYPE.explosions, (_, loadedResources) => {
	Object.values(loadedResources).filter(resource => !resource.name.includes('_image')).forEach(resource => {
		const explosionSheet = resource.spritesheet;
		const sprite = new PIXI.AnimatedSprite(Object.values(explosionSheet.animations)[0]);
		sprite.pivot.set(sprite.width / 2, sprite.height / 2);
		store.explosionSprites.push(sprite);
	});
});

loadResources(store, background, LOAD_TYPE.background, (_, loadedResources) => {
	Object.values(loadedResources).filter(resource => !resource.name.includes('_image')).forEach(resource => {
		const backgroundSheet = resource.spritesheet;
		const spriteExample = new PIXI.AnimatedSprite(Object.values(backgroundSheet.animations)[0]);

		const imageRatio = spriteExample.width / spriteExample.height;

		spriteExample.height = store.screenSize.height;
		spriteExample.width = store.screenSize.height * imageRatio;

		[...Array(Math.ceil(store.screenSize.width / spriteExample.width))].forEach((_, i) => {
			const sprite = new PIXI.AnimatedSprite(Object.values(backgroundSheet.animations)[0]);
			sprite.height = spriteExample.height;
			sprite.width = spriteExample.width;
			sprite.position.set(spriteExample.width * i, 0);
			sprite.play();
			store.app.stage.addChildAt(sprite, 0);
		});
	});
});

const updateLoop = () => {
	store[`scene${store.sceneIndex}`].tickCounter += store[`scene${store.sceneIndex}`].tickSpeed;
	store.ticker(store[`scene${store.sceneIndex}`].tickCounter);
	requestAnimationFrame(updateLoop);
};

updateLoop();
domInteractions(store);
setContentLanguages(store.currentLanguage);

if (store.isInstallationRun) {
	document.getElementById('loading-experience').classList.add('is-gone');
	document.getElementById('open-story').classList.add('is-hidden');
	document.getElementById('autoplay-button').classList.add('is-hidden');
	document.getElementById('subtitle-button').classList.add('is-hidden');
	document.getElementById('change-language').classList.add('is-hidden');
	document.getElementById('open-about').classList.add('is-hidden');

	document.getElementById('landing-overlay').classList.remove('is-hidden');
	document.getElementById('landing-overlay').classList.remove('is-gone');

	document.getElementsByTagName('body')[0].classList.add('cursor-hidden');

	document.getElementsByClassName('content-start')[0].innerHTML = '<br/>druk op een knop om te starten';
}

const totalFiles =
	3 * 6 * 11 + // audio
	11 * 2 + // 1th parallels
	3 * 2 + // 1th tunnel
	30; // random files

const progressBarElement = document.getElementById('loadbar-indicator');
const currentFileElement = document.getElementById('loadbar-filename');

store.loaderFileNameInterval = setInterval(() => {
	const entries = performance.getEntriesByType('resource');
	progressBarElement.style.width = `${entries.length / totalFiles * 100}%`;
	currentFileElement.innerHTML = `${entries.length} / ${totalFiles} - ${entries[entries.length - 1].name.split('/')[3]}`;
}, 5);
