import { makeParallelVisible } from './makeParallelVisible';
import { clamp, debounce } from 'lodash';
import { addReflectionFilter } from './triggerParallelFilter';
import { setSceneTimers } from './setSceneTimers';

const switchParallel = (store) => {
	if (store.hasLoaderInTunnel || !store.hasStartedExperience) {
		return;
	}

	const curScene = store[`scene${store.sceneIndex}`];
	curScene.parallelIndex = curScene.parallelIndex === 10 ? 0 : curScene.parallelIndex + 1;
	makeParallelVisible(store);

	// The chance to speed up must be higher than to slow down
	curScene.fallSpeed = clamp(
		curScene.fallSpeed + (Math.random() - 0.3) * 0.002,
		0.00001,
		0.07,
	);
};

let hasReceivedDeviceOrientation = false;
let startX = 0;
let startY = 0;

const debouncedClick = debounce((store) => {
	switchParallel(store);
	addReflectionFilter(store);
}, 20);

export const interactionHandler = (store) => {
	window.addEventListener('mousemove', (event) => {
		if (!store.hasStartedExperience) {
			return;
		}

		if (store.isInstallationRun) {
			store.mouseXY = [
				clamp(event.movementX * 0.0005 + store.mouseXY[0], -1, 1),
				clamp(event.movementY * 0.0005 + store.mouseXY[1], -1, 1),
			];
			return;
		}

		const normX = (event.clientX - store.screenSize.width / 2) / store.screenSize.width * 2;
		const normY = (event.clientY - store.screenSize.height / 2) / store.screenSize.height * 2;
		store.mouseXY = [normX, normY];
	});

	store.app.view.addEventListener('touchend', () => {
		debouncedClick(store);
	});

	store.app.view.addEventListener('pointerup', () => {
		debouncedClick(store);
	});

	window.addEventListener('keydown', () => {
		store.isKeyDown = true;
	});

	window.addEventListener('keyup', () => {
		store.isKeyDown = false;
		switchParallel(store);

		if (store.isInstallationRun && !store.hasStartedExperience) {
			store.hasStartedExperience = true;

			setTimeout(() => {
				makeParallelVisible(store);
				setSceneTimers(store);
			}, 2000);

			setTimeout(() => {
				document.getElementById('landing-overlay').classList.add('is-hidden');

				setTimeout(() => {
					document.getElementById('landing-overlay').classList.remove('is-gone');
				}, 500);
			}, 500);

			store.app.view.requestPointerLock = store.app.view.requestPointerLock || store.app.view.mozRequestPointerLock;
			store.app.view.requestPointerLock();
		}
	});

	const handleOrientation = (event) => {
		if (!hasReceivedDeviceOrientation) {
			startX = event.gamma;
			startY = event.beta;
			hasReceivedDeviceOrientation = true;
		}

		var curX = event.gamma;
		var curY = event.beta;

		store.mouseXY = [
			clamp((startX - curX) * 0.05, -1, 1),
			clamp((startY - curY) * -0.05, -1, 1),
		];
	};

	window.addEventListener('deviceorientation', handleOrientation, true);
};
