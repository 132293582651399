import * as PIXI from 'pixi.js';
import { sample } from 'lodash';

// The generic function to append a (Animated)Sprite at the end of the tunnel. Creates a tunnelLayer object and prepends the sprite in the stage
export const addLayerToTunnel = (store, otherSprite, isWell) => {
	const spriteScene = store[`scene${store.sceneIndex}`];

	const sprite = otherSprite || (
		spriteScene.randomSelectEnvOnStart
			? new PIXI.Sprite(sample(store.tunnelResourcesInScene).texture)
			: new PIXI.Sprite(store.tunnelResourcesInScene[store.tunnelLayersCounter % store.tunnelResourcesInScene.length].texture)
	);

	sprite.visible = false;
	sprite.pivot.set(sprite.width / 2, sprite.height / 2);

	const startPosition = [
		store.screenSize.width / 2 + Math.random() * spriteScene.randomDisplaceEnvOnStart,
		store.screenSize.height / 2 + Math.random() * spriteScene.randomDisplaceEnvOnStart,
	];

	let startRotation = store.tunnelLayersCounter * spriteScene.linearRotateEnvOnStartMult;

	if (spriteScene.randomRotateEnvOnStart || isWell) {
		startRotation += Math.random() * Math.PI * 2;
	}

	let startScale = (() => {
		if (isWell) {
			return 1.3;
		}

		return Math.random() * spriteScene.randomScaleEnvOnStart + 1;
	})();

	// This value should match the media query in styles.scss
	if (store.isInstallationRun || store.screenSize.width < 765) {
		startScale += 0.6;
	}

	store.tunnelLayersCounter += 1;

	const zPosition = store.tunnelLayerFarthestPos + spriteScene.distance;
	store.tunnelLayerFarthestPos = zPosition;

	store.tunnelLayers.push({
		sprite,
		spriteScene,
		zPosition,
		startPosition,
		startScale,
		startRotation,
		isWell,
	});

	store.tunnelLayerCounter += 1;

	if (sprite instanceof PIXI.AnimatedSprite) {
		sprite.play();
	}

	if (isWell) {
		store.wellContainer.addChildAt(sprite, 0);
	} else {
		store.tunnelContainer.addChildAt(sprite, 0);
	}
};
