import { Howl } from 'howler';
import { loaderSounds } from './allAssets';

const howlsPerScene = loaderSounds.map(
	(src) => new Howl({
		src: [src],
		loop: true
	})
);

export const playLoaderSound = (store) => {
	const currentHowl = howlsPerScene[store.sceneIndex];
	if (!currentHowl) {
		return;
	}
	currentHowl.play();
	currentHowl.fade(0, 1, 3000);
};

export const stopLoaderSound = (store) => {
	howlsPerScene
		.filter((howl) => howl.playing())
		.forEach((howl) => howl.fade(howl.volume(), 0, 1000));
};
