
import { loadSceneResources } from './loadResources';
import { setSceneTimers } from './setSceneTimers';
import { addLayerToTunnel } from './addLayerToTunnel';
import { resetStageFilters } from './triggerParallelFilter';

export const initiateScene = (store, shouldReset) => {
	console.log('initiate scene');

	resetStageFilters(store);

	if (store.hasStartedExperience) {
		setSceneTimers(store);
	}

	// This is useful for when the settings have changed to reflect them immediately. This is statement should never run when going from scene to scene "naturally"
	if (shouldReset) {
		loadSceneResources(store);

		store.tunnelLayers.forEach((layer) => {
			store.tunnelContainer.removeChild(layer.sprite);
		});

		store.tunnelLayers = [];
		store.tunnelLayerFarthestPos = store.sceneZ + 3;
	}

	store.hasLoaderInTunnel = false;
	let sceneZTarget = null;
	const curScene = store[`scene${store.sceneIndex}`];

	let updatedDisplacementFarEndY = 0;

	// The ticker function that makes everything move
	store.ticker = (tick) => {
		// First conditional to check if it should animate to the loader target or else animate normally
		if (sceneZTarget) {
			store.sceneZ += (sceneZTarget - store.sceneZ) * 0.01;
		} else if (store.hasStartedExperience) {
			curScene.fallAccelerator += (curScene.fallSpeed - curScene.fallAccelerator) * 0.005;
			store.sceneZ += Math.max(
				0,
				curScene.fallSpeed / 2 + curScene.fallAccelerator / 2,
			);
		}

		// If no resources, nothing is loaded so we have to wait for it
		if (!store.tunnelResourcesInScene) {
			return;
		}

		// Sets the target 2 units before the loader sprite, there should be a loader and no activeParallelElement
		// The activeParallelElement should be set to null after the explosion animation
		if (store.hasLoaderInTunnel && !store.activeParallelElement) {
			sceneZTarget = store.tunnelLayerFarthestPos - 2;
		}

		// This appends layers to the tunnel on the fly, makes the tunnel agnostic of fallSpeed, distance, fps, etcetera
		if (
			store.tunnelLayerFarthestPos - store.sceneZ < 20 &&
			store.tunnelLayers.length < curScene.maxElementsInView &&
			// eslint-disable-next-line no-unmodified-loop-condition
			!store.hasLoaderInTunnel
		) {
			addLayerToTunnel(store);
		}

		// The main animation function. Filled with math funcs to apply the given styles on each layer in the tunnel
		// Main animation ingredients are scale, position, rotation
		for (let i = 0; i < store.tunnelLayers.length; i++) {
			const { sprite, spriteScene, zPosition, startPosition, startRotation, startScale, isWell } = store.tunnelLayers[i];

			const scale = 1 / (zPosition - store.sceneZ) * startScale;

			if (scale > spriteScene.scaleCutoff) {
				store.tunnelLayers.splice(i, 1);
				isWell
					? store.wellContainer.removeChild(sprite)
					: store.tunnelContainer.removeChild(sprite);
				return;
			}

			const scalePow = scale ** 2;

			sprite.visible = true;
			sprite.width = scale * Math.max(store.screenSize.width, store.screenSize.height);
			sprite.scale.y = sprite.scale.x;

			updatedDisplacementFarEndY = store.hasStartedExperience
				? Math.min(updatedDisplacementFarEndY + 0.0001, spriteScene.displaceFarEndY)
				: store.isInstallationRun ? 8 : 1.4;

			sprite.position.set(
				// use static startPosition to not drift off
				startPosition[0] + ((isWell ? i / 4 : 1) * ((store.mouseXY[0] * (1 / scale * spriteScene.mouseMultX) * (store.isKeyDown || store.aggregateMouseVelocity > 0.2 ? 0 : 1)) + (1 / scalePow * (spriteScene.displaceFarEndX + (Math.sin(tick) * spriteScene.displaceFarEndSinusX))))),
				startPosition[1] + ((isWell ? i / 4 : 1) * ((store.mouseXY[1] * (1 / scale * spriteScene.mouseMultY) * (store.isKeyDown || store.aggregateMouseVelocity > 0.2 ? 0 : 1)) + (1 / scalePow * (updatedDisplacementFarEndY + (Math.cos(tick) * spriteScene.displaceFarEndCosinusY))))),
			);

			if (isWell) {
				sprite.rotation = startRotation;
			} else {
				sprite.rotation = startRotation + Math.sin(tick + (zPosition * spriteScene.sinusoidalOffset)) * spriteScene.sinusoidalAmplitude;
			}

			if (store.activeParallelElement) {
				store.activeParallelElement.position.set(
					store.screenSize.width / 2 + spriteScene.parallelX + (store.mouseXY[0] * spriteScene.parallelMouseMultX),
					store.screenSize.height / 2 + spriteScene.parallelY + (store.mouseXY[1] * spriteScene.parallelMouseMultY),
				);

				store.activeParallelElement.width = curScene.parallelScale * (Math.min(store.screenSize.width, store.screenSize.height) / 2) + (store.mouseXY[1] * curScene.parallelScaleMouseMultY);
				store.activeParallelElement.scale.y = store.activeParallelElement.scale.x;
			}

			if (store.isInstallationRun) {
				store.mouseXY[0] -= store.mouseXY[0] * 0.0001;
				store.mouseXY[1] -= store.mouseXY[1] * 0.0001;
			}
		}
	};
};
