import { triggerExplosionFilter } from './triggerExplosionFilter';
import { makeParallelVisible } from './makeParallelVisible';
import { addLayerToTunnel } from './addLayerToTunnel';
import { loadSceneResources } from './loadResources';
import { playLoaderSound, stopLoaderSound } from './playLoaderSound';

let addLoaderTimeout;
let removeParallelTimeout;

let hasNewSceneTimedout = false;
let hasNewSceneLoaded = false;
let newSceneTimeout;

export const setSceneTimers = (store) => {
	if (store.sceneIndex === 6) {
		setTimeout(() => {
			if (store.isInstallationRun) {
				window.location.reload();
				return;
			}

			document.getElementById('restart-experience').classList.remove('is-gone');
			document.getElementById('restart-experience').classList.remove('is-hidden');
		}, 10000);

		return;
	}

	const curScene = store[`scene${store.sceneIndex}`];

	// The time at which the loader should be appended in the tunnel
	// No more of the current tunnel elements are then added after it
	clearTimeout(addLoaderTimeout);

	addLoaderTimeout = setTimeout(() => {
		playLoaderSound(store);
		store.hasLoaderInTunnel = true;
		addLayerToTunnel(store, store.curLoaderSprite);
	}, curScene.triggerNextSceneTimeout);

	// A moment after the loader is appended, the parallel should explode. Then the tunnel animates towards the loader and stays there for a bit
	// At the end of the explosion the parallel is removed. After 5 seconds the next scene is started
	clearTimeout(removeParallelTimeout);
	clearTimeout(newSceneTimeout);
	hasNewSceneTimedout = false;
	hasNewSceneLoaded = false;

	const triggerNewScene = () => {
		store.newScene();

		setTimeout(() => {
			makeParallelVisible(store);
			stopLoaderSound();
		}, curScene.delayToShowParallelAfterLoader);
	};

	removeParallelTimeout = setTimeout(() => {
		triggerExplosionFilter(store, () => {
			store.tunnelContainer.removeChild(store.activeParallelElement);
			store.activeParallelElement = null;

			document.querySelector('#subtitle').innerHTML = '';
		});

		store.sceneIndex += 1;
		loadSceneResources(store, {
			onParallelsCallback: () => {
				hasNewSceneLoaded = true;

				if (hasNewSceneTimedout) {
					triggerNewScene();
				}
			},
		});

		newSceneTimeout = setTimeout(() => {
			hasNewSceneTimedout = true;

			if (hasNewSceneLoaded) {
				triggerNewScene();
			}
		}, store.showLoaderMinimumTime);
	}, curScene.triggerNextSceneTimeout);
};
