import { Howl } from 'howler';
import { setSceneTimers } from './setSceneTimers';
import { makeParallelVisible } from './makeParallelVisible';
import { setContentLanguages } from './contentLanguages';
import { soundtrack } from './allAssets';

const soundtrackSound = new Howl({ src: soundtrack });

export const domInteractions = (store) => {
	document.getElementById('start-experience').onclick = (event) => {
		soundtrackSound.volume(store.soundTrackVolume);
		soundtrackSound.play();

		document.getElementById('landing-overlay').classList.add('is-hidden');

		setTimeout(() => {
			document.getElementById('landing-overlay').classList.add('is-gone');
		}, 400);

		setTimeout(() => {
			setSceneTimers(store);
			makeParallelVisible(store);
		}, 4000);

		store.hasStartedExperience = true;
	};

	document.getElementById('restart-experience').onclick = () => {
		window.location.reload();
	};

	document.getElementById('change-language').onclick = () => {
		store.currentLanguage = store.currentLanguage === 'en' ? 'nl' : 'en';
		setContentLanguages(store.currentLanguage);
	};

	document.getElementById('autoplay-button').onclick = () => {
		store.autoChangeParallels = !store.autoChangeParallels;

		if (store.autoChangeParallels) {
			document.getElementById('autoplay-button-mark').classList.remove('is-hidden');
			document.getElementById('autoplay-button-empty').classList.add('is-hidden');
		} else {
			document.getElementById('autoplay-button-empty').classList.remove('is-hidden');
			document.getElementById('autoplay-button-mark').classList.add('is-hidden');
		}
	};

	store.autoChangeParallels = document.location.search.includes('autoplay');
	if (store.autoChangeParallels) {
		document.getElementById('autoplay-button-mark').classList.remove('is-hidden');
		document.getElementById('autoplay-button-empty').classList.add('is-hidden');
	} else {
		document.getElementById('autoplay-button-empty').classList.remove('is-hidden');
		document.getElementById('autoplay-button-mark').classList.add('is-hidden');
	}

	document.getElementById('subtitle-button').onclick = () => {
		store.showSubtitles = !store.showSubtitles;

		if (store.showSubtitles) {
			document.getElementById('subtitle-button-mark').classList.remove('is-hidden');
			document.getElementById('subtitle-button-empty').classList.add('is-hidden');
			document.getElementById('subtitle').classList.remove('is-hidden');
		} else {
			document.getElementById('subtitle-button-empty').classList.remove('is-hidden');
			document.getElementById('subtitle-button-mark').classList.add('is-hidden');
			document.getElementById('subtitle').classList.add('is-hidden');
		}
	};

	store.showSubtitles = document.location.search.includes('subtitles');
	if (store.showSubtitles) {
		document.getElementById('subtitle-button-mark').classList.remove('is-hidden');
		document.getElementById('subtitle-button-empty').classList.add('is-hidden');
		document.getElementById('subtitle').classList.remove('is-hidden');
	} else {
		document.getElementById('subtitle-button-empty').classList.remove('is-hidden');
		document.getElementById('subtitle-button-mark').classList.add('is-hidden');
		document.getElementById('subtitle').classList.add('is-hidden');
	}

	document.getElementById('open-about').onclick = () => {
		document.getElementById('about').classList.toggle('is-visible');
	};

	document.getElementById('close-about').onclick = () => {
		document.getElementById('about').classList.remove('is-visible');
	};

	document.getElementById('open-story').onclick = () => {
		document.getElementById('story').classList.toggle('is-visible');
	};

	document.getElementById('close-story').onclick = () => {
		document.getElementById('story').classList.remove('is-visible');
	};
};
