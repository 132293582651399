import * as PIXI from 'pixi.js';
import displacementImage from '../../assets/filter/displacement.png';

const displacementSprite = PIXI.Sprite.from(displacementImage);

export const triggerDisplacementFilter = (store, addTo) => {
	const startDisplacementScale = 30;
	const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite, startDisplacementScale);
	displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;

	addTo.addChild(displacementSprite);

	let count = 0;

	const displacementAnimationLoop = () => {
		displacementFilter.scale.set(
			startDisplacementScale + store.mouseXY[1] * -15,
			startDisplacementScale + store.mouseXY[1] * -15,
		);

		displacementSprite.x = count + store.mouseXY[1];
		count += 1;

		requestAnimationFrame(displacementAnimationLoop);
	};

	addTo.filters = [displacementFilter];

	displacementAnimationLoop();
};
