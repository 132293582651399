import * as PIXI from 'pixi.js';
import { initialLoadEnd } from './initialLoadEnd';

/** PIXI assets go through a few stages before they can be used as sprites
 * First it is a URL -> comes from allAssets.js which are all arrays of strings
 * The URL is passed in the loader. Multiple loaders have been created for eacht asset type to not get an error for trying to load more type at once.
 * The PIXI loader can only handle one set of resources at a time.
 * Then the Loader returns a Recource. The resource can be used to create a Sprite or an AnimatedSprite.
 * Prefixes are used to get consistent names of resources and sprites.
 */

export const LOAD_TYPE = {
	background: {
		loader: new PIXI.Loader(),
		prefix: 'background',
	},
	well: {
		loader: new PIXI.Loader(),
		prefix: 'well',
	},
	explosions: {
		loader: new PIXI.Loader(),
		prefix: 'explosion',
	},
	tunnel: {
		loader: new PIXI.Loader(),
		prefix: 'tunnel',
	},
	parallels: {
		loader: new PIXI.Loader(),
		prefix: 'parallel',
	},
	loader: {
		loader: new PIXI.Loader(),
		prefix: 'loader',
	},
};

Object.values(LOAD_TYPE).forEach((type) => {
	// It can happen that resources are still hanging when the scene is already over. We need a new resource to not stall the experience.
	type.loader.onError = () => {
		type.loader = new PIXI.Loader();
	};
});

export const loadResources = (store, resourcesArray, TYPE, loadCallback) => {
	resourcesArray.forEach((path, i) => {
		if (TYPE.loader.resources[`${TYPE.prefix}-${store.sceneIndex}-${i}`]) {
			return;
		}

		TYPE.loader.add(`${TYPE.prefix}-${store.sceneIndex}-${i}`, path);
	});

	// .load is called after all the resources from resourcesArray are resolved. It will return all previously resolved resources, hence the filter functions in the users of this util
	TYPE.loader.load(loadCallback);
};

export const loadSceneResources = (store, callbacks) => {
	// Tunnel resources
	loadResources(store, store.allAssets[store.sceneIndex].scene, LOAD_TYPE.tunnel, (_, loadedResources) => {
		store.tunnelResourcesInScene = Object.values(loadedResources).filter(resource => (
			resource.name.includes(`${LOAD_TYPE.tunnel.prefix}-${store.sceneIndex}`))
		);
	});

	// Parallels resources
	loadResources(store, store.allAssets[store.sceneIndex].parallels, LOAD_TYPE.parallels, (_, loadedResources) => {
		// The parallels are not kept track of as neatly as the tunnel assets, so need to remove from stage when new ones are requested
		store.allParallelsInScene.forEach((sprite) => {
			store.parallelsContainer.removeChild(sprite);
		});

		store.allParallelsInScene = [];

		[...Array(11)].forEach((_, i) => {
			const parallelSheet = loadedResources[`${LOAD_TYPE.parallels.prefix}-${store.sceneIndex}-${i}`]?.spritesheet;

			if (!parallelSheet) {
				return;
			}

			const sprite = new PIXI.AnimatedSprite(Object.values(parallelSheet.animations)[0]);

			store.allParallelsInScene.push(sprite);

			sprite.pivot.set(sprite.width / 2, sprite.height / 2);
			sprite.visible = false;
			store.parallelsContainer.addChild(sprite);
		});

		callbacks?.onParallelsCallback();

		if (!store.hasStartedExperience) {
			initialLoadEnd(store);
		}
	});

	// Loader resource
	loadResources(store, store.allAssets[store.sceneIndex].loader, LOAD_TYPE.loader, (_, loadedResources) => {
		store.curLoaderSprite = Object.values(loadedResources).filter(resource => (
			resource.name.includes(`${LOAD_TYPE.loader.prefix}-${store.sceneIndex}`)) &&
			!resource.name.includes('_image')
		).map(resource => {
			const loaderSheet = resource.spritesheet;
			return new PIXI.AnimatedSprite(Object.values(loaderSheet.animations)[0]);
		})[0];
	});
};
