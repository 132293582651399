import { Howl } from 'howler';
import { sceneSounds, parallelSwitch } from './allAssets';
import subtitles from '../subtitles';

const parallelSwitchSound = new Howl({ src: parallelSwitch });
const howlsPerParallel = sceneSounds.map((parallelSounds) =>
	parallelSounds.map(({ en, nl, vo }) => ({
		en: new Howl({ src: [en] }),
		nl: new Howl({ src: [nl] }),
		vo: new Howl({ src: [vo] }),
	}))
);

export const playParallelSound = (store) => {
	const sceneIndex = store.sceneIndex;
	const parallelIndex = store[`scene${store.sceneIndex}`].parallelIndex;
	const soundKey = store.showSubtitles ? store.currentLanguage : 'vo';

	parallelSwitchSound.play();
	howlsPerParallel[sceneIndex]?.[parallelIndex]?.[soundKey]?.play();

	const subtitleKey = `${sceneIndex + 1}.${parallelIndex + 1}.${soundKey}`;
	document.querySelector('#subtitle').innerHTML = subtitles[subtitleKey] || '';
};
