import { triggerParallelFilter, addReflectionFilter } from './triggerParallelFilter';
import { triggerExplosionFilter } from './triggerExplosionFilter';
import { playParallelSound } from './playParallelSound';

let nextParallelTimeout;

export const makeParallelVisible = (store, fromTimeout = false) => {
	const curScene = store[`scene${store.sceneIndex}`];
	const sprite = store.allParallelsInScene[curScene.parallelIndex];

	clearTimeout(nextParallelTimeout);

	if (store.autoChangeParallels && store.sceneIndex !== 6) {
		nextParallelTimeout = setTimeout(() => {
			if (store.hasLoaderInTunnel) {
				return;
			}

			curScene.parallelIndex = (curScene.parallelIndex + 1) % 11;
			makeParallelVisible(store, true);
		}, 2500);
	}

	playParallelSound(store);

	const triggerFilter = fromTimeout ? triggerExplosionFilter : triggerParallelFilter;

	triggerFilter(store, () => {
		if (store.activeParallelElement) {
			store.activeParallelElement.visible = false;
		}

		if (!sprite) {
			return;
		}
		store.activeParallelElement = sprite;
		sprite.position.set(
			store.screenSize.width / 2 + curScene.parallelX,
			store.screenSize.height / 2 + curScene.parallelY,
		);
		sprite.visible = true;
		sprite.animationSpeed = curScene.playbackSpeed;
		sprite.play();
	});

	if (fromTimeout) {
		const reflectionInterval = setInterval(() => {
			addReflectionFilter(store);
		}, 20);

		setTimeout(() => {
			clearInterval(reflectionInterval);
			store.app.stage.filters = [];
		}, 500);
	}
};
