export default {
	'1.1.en': 'THE MERMAID PROGRAMMER CODES A NEW INTERNET.',
	'1.1.nl': 'DE ZEEMEERMIN PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.2.en': 'THE CENTAUR PROGRAMMER CODES A NEW INTERNET.',
	'1.2.nl': 'DE CENTAUR PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.3.en': 'THE HIPPOCAMPUS PROGRAMMER CODES A NEW INTERNET.',
	'1.3.nl': 'DE HIPPOCAMPUS PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.4.en': 'THE HYDRA PROGRAMMER CODES A NEW INTERNET.',
	'1.4.nl': 'DE HYDRA PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.5.en': 'THE REPTILIAN PROGRAMMER CODES A NEW INTERNET.',
	'1.5.nl': 'DE REPTIEL PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.6.en': 'THE SPHINX PROGRAMMER CODES A NEW INTERNET.',
	'1.6.nl': 'DE SPHINX PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.7.en': 'THE AMOEBA PROGRAMMER CODES A NEW INTERNET.',
	'1.7.nl': 'DE AMOEBE PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.8.en': 'THE ROOSTER PROGRAMMER CODES A NEW INTERNET.',
	'1.8.nl': 'DE HAAN PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.9.en': 'THE PEGASUS PROGRAMMER CODES A NEW INTERNET.',
	'1.9.nl': 'DE PEGASUS PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.10.en': 'THE DRAGON PROGRAMMER CODES A NEW INTERNET.',
	'1.10.nl': 'DE DRAAK PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'1.11.en': 'THE JENGU PROGRAMMER CODES A NEW INTERNET.',
	'1.11.nl': 'DE JENGU PROGRAMMEUR CODEERT EEN NIEUW INTERNET.',
	'2.1.en': 'THE INTERNET SHALL BE A SQUARE!',
	'2.1.nl': 'HET INTERNET ZAL EEN VIERKANT ZIJN!',
	'2.2.en': 'THE INTERNET SHALL BE A DONUT!',
	'2.2.nl': 'HET INTERNET ZAL EEN DONUT ZIJN!',
	'2.3.en': 'THE INTERNET SHALL BE FLAT!',
	'2.3.nl': 'HET INTERNET ZAL EEN VLAK ZIJN!',
	'2.4.en': 'THE INTERNET SHALL BE A HEXAGON!',
	'2.4.nl': 'HET INTERNET ZAL EEN ZESHOEK ZIJN!',
	'2.5.en': 'THE INTERNET SHALL BE A MOBIUS!',
	'2.5.nl': 'HET INTERNET ZAL EEN MOBIUS ZIJN!',
	'2.6.en': 'THE INTERNET SHALL BE A CONE!',
	'2.6.nl': 'HET INTERNET ZAL EEN KEGEL ZIJN!',
	'2.7.en': 'THE INTERNET SHALL BE AN EGG!',
	'2.7.nl': 'HET INTERNET ZAL EEN EI ZIJN!',
	'2.8.en': 'THE INTERNET SHALL BE A CYLINDER!',
	'2.8.nl': 'HET INTERNET ZAL EEN CILINDER ZIJN!',
	'2.9.en': 'THE INTERNET SHALL BE GEOMETRIC!',
	'2.9.nl': 'HET INTERNET ZAL EEN GEOMETRIE ZIJN!',
	'2.10.en': 'THE INTERNET SHALL BE TWO!',
	'2.10.nl': 'HET INTERNET ZAL EEN TWEE ZIJN!',
	'2.11.en': 'THE INTERNET SHALL BE SPLIT!',
	'2.11.nl': 'HET INTERNET ZAL EEN SPLITSING ZIJN!',
	'3.1.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #903622',
	'3.1.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #903622',
	'3.2.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #8D9462',
	'3.2.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #8D9462',
	'3.3.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #629473',
	'3.3.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #629473',
	'3.4.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #3DC2AA',
	'3.4.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #3DC2AA',
	'3.5.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #15C5E5',
	'3.5.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #15C5E5',
	'3.6.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #1F49A2',
	'3.6.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #1F49A2',
	'3.7.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #4240C6',
	'3.7.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #4240C6',
	'3.8.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #712CB2',
	'3.8.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #712CB2',
	'3.9.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #DA35CB',
	'3.9.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #DA35CB',
	'3.10.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #C12379',
	'3.10.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #C12379',
	'3.11.en': 'THE ANCIENT AVATAR DREAMS ABOUT THE COLOR  #C33556',
	'3.11.nl': 'DE OUDE AVATAR DROOMT OVER DE KLEUR #C33556',
	'4.1.en': 'MEOW MEOW USE A.I REACTIVE MACHINES.',
	'4.1.nl': 'MIAUW MIAUW GEBRUIKT A.I REACTIEVE MACHINES.',
	'4.2.en': 'MEOW MEOW USE A.I LIMITED MEMORY.',
	'4.2.nl': 'MIAUW MIAUW GEBRUIKT A.I BEPERKT GEHEUGEN.',
	'4.3.en': 'MEOW MEOW USE A.I SEGMENTATION.',
	'4.3.nl': 'MIAUW MIAUW GEBRUIKT A.I GEBOUW GENERATOR.',
	'4.4.en': 'MEOW MEOW USE A.I BUILDING GENERATOR.',
	'4.4.nl': 'MIAUW MIAUW GEBRUIKT A.I GEBOUW GENERATOR.',
	'4.5.en': 'MEOW MEOW USE A.I MOTION MODEL.',
	'4.5.nl': 'MIAUW MIAUW GEBRUIKT A.I BEWEGINGSMODEL.',
	'4.6.en': 'MEOW MEOW USE A.I GAN.',
	'4.6.nl': 'MIAUW MIAUW GEBRUIKT A.I GAN.',
	'4.7.en': 'MEOW MEOW USE A.I STYLE TRANSFER.',
	'4.7.nl': 'MIAUW MIAUW GEBRUIKT A.I STIJLOVERDRACHT.',
	'4.8.en': 'MEOW MEOW USE A.I NEURAL.',
	'4.8.nl': 'MIAUW MIAUW GEBRUIKT A.I NEURAAL.',
	'4.9.en': 'MEOW MEOW USE A.I OPTIMAL.',
	'4.9.nl': 'MIAUW MIAUW GEBRUIKT A.I OPTIMAAL.',
	'4.10.en': 'MEOW MEOW USE A.I TRAINING MODEL.',
	'4.10.nl': 'MIAUW MIAUW GEBRUIKT A.I OPLEIDINGSMODEL.',
	'4.11.en': 'MEOW MEOW USE A.I GPT-2',
	'4.11.nl': 'MIAUW MIAUW GEBRUIKT A.I GPT-2.',
	'5.1.en': '          X̸̨̝̘̲͍̭̜͚̬̲̩̀ͅX̷̡͉͍̟͌̋́̎̈̕͝X̴̘̼͙̳͓̳̟͉̤̋̒̈́̑̈X̸̢̙͈̠̺̳͐̅̊̄̂̀̽̒̐̉̀͘̚͠͠Ẍ̴̨̢̨̖̱̼̪͍́̎̋̓̾̀͜͜͝ͅ    ',
	'5.1.nl': '          X̸̨̝̘̲͍̭̜͚̬̲̩̀ͅX̷̡͉͍̟͌̋́̎̈̕͝X̴̘̼͙̳͓̳̟͉̤̋̒̈́̑̈X̸̢̙͈̠̺̳͐̅̊̄̂̀̽̒̐̉̀͘̚͠͠Ẍ̴̨̢̨̖̱̼̪͍́̎̋̓̾̀͜͜͝ͅ             ',
	'5.2.en': '           ̸̬̝̹͔̘͍̌̎̆͗̉̒̐͌̾͝X̴͕̅̒͌̆͠͠X̴̧̎̈́͗̒̆̏͊͑͌́̏̇̀̕͜͝X̶̜͕̲͈͇̼̪̙̰̘̖̦̓̐̿̌̐̇̒͊̌̌͝X̷̲̫̜̯͈͚̉̏̇͌̏́̎͠X̵̜̪̹̫̻̫̫̳̪͕̟̩͐̓̚͠           ',
	'5.2.nl': '             ̸̬̝̹͔̘͍̌̎̆͗̉̒̐͌̾͝X̴͕̅̒͌̆͠͠X̴̧̎̈́͗̒̆̏͊͑͌́̏̇̀̕͜͝X̶̜͕̲͈͇̼̪̙̰̘̖̦̓̐̿̌̐̇̒͊̌̌͝X̷̲̫̜̯͈͚̉̏̇͌̏́̎͠X̵̜̪̹̫̻̫̫̳̪͕̟̩͐̓̚͠         ',
	'5.3.en': '            ̸̙̮̻̜̱̈͒̏̆̓̉͜X̶̢̛̻̮̝͈̝̫̤̘̙́̈́͠X̴̡͓̮̦̥̘͕͔̠̖͈̞̩̩̌͝X̸̫̱̺͍͍̚X̶̨̙͓̫̖͕̙͕͕̼̟͉̠̠̜̊͠X̷̛͍̓           ',
	'5.3.nl': '            ̸̙̮̻̜̱̈͒̏̆̓̉͜X̶̢̛̻̮̝͈̝̫̤̘̙́̈́͠X̴̡͓̮̦̥̘͕͔̠̖͈̞̩̩̌͝X̸̫̱̺͍͍̚X̶̨̙͓̫̖͕̙͕͕̼̟͉̠̠̜̊͠X̷̛͍̓           ',
	'5.4.en': '              ̵̡̨͙̘̯̫̮̝͉̲̘͖̺̘̍̅͛͑̒͝X̸̢̜̙̦̳͔͚̝͋͗̍̐͊͛̊̔͠ͅX̸̩̘̹͇̘̺̻̮͇͛̽̽̿ͅX̸̧̢͓̘̟̗̼̠̜͈̟̱̔ͅX̸̢̧̨̯͇̱͚̣̤̰̹͙̩̙̬̏̋̏̔̽͒̋́͒̂̚͝X̵̡̼͎̼̉̊̒͂̉̿̔̓͘̚͠         ',
	'5.4.nl': '              ̵̡̨͙̘̯̫̮̝͉̲̘͖̺̘̍̅͛͑̒͝X̸̢̜̙̦̳͔͚̝͋͗̍̐͊͛̊̔͠ͅX̸̩̘̹͇̘̺̻̮͇͛̽̽̿ͅX̸̧̢͓̘̟̗̼̠̜͈̟̱̔ͅX̸̢̧̨̯͇̱͚̣̤̰̹͙̩̙̬̏̋̏̔̽͒̋́͒̂̚͝X̵̡̼͎̼̉̊̒͂̉̿̔̓͘̚͠          ',
	'5.5.en': '            ̵̡̡͎͙̭̟̝̟̼̙̈́̈̈X̶͙̗̐͑̏͛̑̽̓͐̓X̶̻̘͂̄̈́͌͌̈́̀͊͊̚͘͝͝X̵͙̻̥̯͕́̾̀̒́̉̓̄̈́͆̉̌͆͋͝X̶̞̆͒̌̈̓̆͜X̵̛̰͇̠̣̻̖̪̰͙̍͑̆̓͐̇̾̊̋̆̔̀͜͝           ',
	'5.5.nl': '             ̵̡̡͎͙̭̟̝̟̼̙̈́̈̈X̶͙̗̐͑̏͛̑̽̓͐̓X̶̻̘͂̄̈́͌͌̈́̀͊͊̚͘͝͝X̵͙̻̥̯͕́̾̀̒́̉̓̄̈́͆̉̌͆͋͝X̶̞̆͒̌̈̓̆͜X̵̛̰͇̠̣̻̖̪̰͙̍͑̆̓͐̇̾̊̋̆̔̀͜͝           ',
	'5.6.en': '               ̴̞̮̗̳̱͍͇̳͉̼̄̿̋X̶̧̲̼̹̗͋̏͊̌́̓̀̏̏͗̋̚͘̚͝X̶̗̝̞̻͙̲͖̖̱̪̀͆̒̿̅̀̿̀̍̒̀̉X̵͖̳̗͕̊͐́͠ͅX̸̘͐͌̀̒̃́͑̂͋̍͐͂̈̐͠X̴̫̱͙͙̏̈́́͋͒̌̈͋̾̆̇̎̕͝         ',
	'5.6.nl': '               ̴̞̮̗̳̱͍͇̳͉̼̄̿̋X̶̧̲̼̹̗͋̏͊̌́̓̀̏̏͗̋̚͘̚͝X̶̗̝̞̻͙̲͖̖̱̪̀͆̒̿̅̀̿̀̍̒̀̉X̵͖̳̗͕̊͐́͠ͅX̸̘͐͌̀̒̃́͑̂͋̍͐͂̈̐͠X̴̫̱͙͙̏̈́́͋͒̌̈͋̾̆̇̎̕͝        ',
	'5.7.en': '                ̸͇̱̿̄́͌͐͝͝X̶̤͖̰͎̥̹̣͚̤̲̞̘̘̉̇̒̍̈̈̇̀̄͗̒̚͝ͅX̶̠̕X̶̨͔̲̳͚̫̜̙͔̓̅́̀͂͌͛͜X̸̡͖̰̞̥͉̜̟͖̿͛͆̾̆̄́̚͝X̴̢̹͈͈̼̘̙̱̂͒̈̆͜       ',
	'5.7.nl': '                ̸͇̱̿̄́͌͐͝͝X̶̤͖̰͎̥̹̣͚̤̲̞̘̘̉̇̒̍̈̈̇̀̄͗̒̚͝ͅX̶̠̕X̶̨͔̲̳͚̫̜̙͔̓̅́̀͂͌͛͜X̸̡͖̰̞̥͉̜̟͖̿͛͆̾̆̄́̚͝X̴̢̹͈͈̼̘̙̱̂͒̈̆͜       ',
	'5.8.en': '                ̸̤̘͕̄̂̀̇̃̽̋̄͆̂͘͠X̶̢̋͑̎̔̆͋͌͌̓̉͊̑͝͝͠X̸͈̟͖͇͓͇̲̤̭̣̫̝̰̊̊́͒̄͊̽̉̄̚͜͝͝X̴̥̣͔̞̙̥̣̤̫̹͛ͅX̴̢̨̺͕͚͉͚̬͓͙̭̯͚̯͓͊̈́͝X̵̬͈̖͎̀̾́̊̓̅̒͘        ',
	'5.8.nl': '                ̸̤̘͕̄̂̀̇̃̽̋̄͆̂͘͠X̶̢̋͑̎̔̆͋͌͌̓̉͊̑͝͝͠X̸͈̟͖͇͓͇̲̤̭̣̫̝̰̊̊́͒̄͊̽̉̄̚͜͝͝X̴̥̣͔̞̙̥̣̤̫̹͛ͅX̴̢̨̺͕͚͉͚̬͓͙̭̯͚̯͓͊̈́͝X̵̬͈̖͎̀̾́̊̓̅̒͘        ',
	'5.9.en': '              ̴̛̮͍͖̬̌͐͌̍͌͒͆͐̕͘̚͠͝ͅX̸̤̠͚͇́͑̂̃͜X̶̢̠̪̪̰̮͔̞̥͓̬̉̉͑̀̐̿̾͋̈́͝X̵̫͈̟̱͔̖͔͕͌̅̑̒́̆̊̔̽͠X̶̰̣̟̫͓̩̟̃͛̍̅̃͠͝Ẍ̸̡̝̠̹̥̪̰̠̾̑̏͐̐̄̍̈́̇̂̿̔          ',
	'5.9.nl': '             ̴̛̮͍͖̬̌͐͌̍͌͒͆͐̕͘̚͠͝ͅX̸̤̠͚͇́͑̂̃͜X̶̢̠̪̪̰̮͔̞̥͓̬̉̉͑̀̐̿̾͋̈́͝X̵̫͈̟̱͔̖͔͕͌̅̑̒́̆̊̔̽͠X̶̰̣̟̫͓̩̟̃͛̍̅̃͠͝Ẍ̸̡̝̠̹̥̪̰̠̾̑̏͐̐̄̍̈́̇̂̿̔           ',
	'5.10.en': '                ̴̙̯̙͒͜X̷̥̖̫̖̮̥̘̰̭̩͑͑́̆̉̑̔̀͘͝Ẍ̸̤͕̹͙͔͖̬͚̣́̾̃̂X̷͕̯̜̯̮͈͔̫̹̙̬̄͒̋́̂͛X̶̛̺̦̰͚̪͚͎̰̬̤͎͗̀̀̏̅͌̔̊͂̉͘͜͠Ẍ̸̢̖̩̺̠̥̟̣͖̳̻̼͇́͛̃̃̽       ',
	'5.10.nl': '               ̴̙̯̙͒͜X̷̥̖̫̖̮̥̘̰̭̩͑͑́̆̉̑̔̀͘͝Ẍ̸̤͕̹͙͔͖̬͚̣́̾̃̂X̷͕̯̜̯̮͈͔̫̹̙̬̄͒̋́̂͛X̶̛̺̦̰͚̪͚͎̰̬̤͎͗̀̀̏̅͌̔̊͂̉͘͜͠Ẍ̸̢̖̩̺̠̥̟̣͖̳̻̼͇́͛̃̃̽        ',
	'5.11.en': '                 X̶̻̘͂̄̈́͌͌̈́̀͊͊̚͘͝͝X̵͙̻̥̯͕́̾̀̒́̉̓̄̈́͆̉̌͆͋͝X̶̞̆͒̌̈̓̆͜X̵̛̰͇̠̣̻̖̪̰͙̍͑̆̓͐̇̾̊̋̆̔̀͜͝     ',
	'5.11.nl': '                X̶̻̘͂̄̈́͌͌̈́̀͊͊̚͘͝͝X̵͙̻̥̯͕́̾̀̒́̉̓̄̈́͆̉̌͆͋͝X̶̞̆͒̌̈̓̆͜X̵̛̰͇̠̣̻̖̪̰͙̍͑̆̓͐̇̾̊̋̆̔̀͜͝       ',
	'6.1.en': '01100001',
	'6.1.nl': '01100001',
	'6.2.en': '01110011',
	'6.2.nl': '01110011',
	'6.3.en': '01100100',
	'6.3.nl': '01100100',
	'6.4.en': '01100001',
	'6.4.nl': '01100001',
	'6.5.en': '01110011',
	'6.5.nl': '01110011',
	'6.6.en': '01100100',
	'6.6.nl': '01100100',
	'6.7.en': '01100001',
	'6.7.nl': '01100001',
	'6.8.en': '01110011',
	'6.8.nl': '01110011',
	'6.9.en': '01100100',
	'6.9.nl': '01100100',
	'6.10.en': '01100110',
	'6.10.nl': '01100110',
	'6.11.en': '01100111',
	'6.11.nl': '01100111',
	'7.1.en': 'THE ENDLESS.',
	'7.1.nl': 'HET ONEINDIGE.',
	'7.2.en': 'THE ENDLESS.',
	'7.2.nl': 'HET ONEINDIGE.',
	'7.3.en': 'THE ENDLESS.',
	'7.3.nl': 'HET ONEINDIGE.',
	'7.4.en': 'THE ENDLESS.',
	'7.4.nl': 'HET ONEINDIGE.',
	'7.5.en': 'THE ENDLESS.',
	'7.5.nl': 'HET ONEINDIGE.',
	'7.6.en': 'THE ENDLESS.',
	'7.6.nl': 'HET ONEINDIGE.',
	'7.7.en': 'THE ENDLESS.',
	'7.7.nl': 'HET ONEINDIGE.',
	'7.8.en': 'THE ENDLESS.',
	'7.8.nl': 'HET ONEINDIGE.',
	'7.9.en': 'THE ENDLESS.',
	'7.9.nl': 'HET ONEINDIGE.',
	'7.10.en': 'THE ENDLESS.',
	'7.10.nl': 'HET ONEINDIGE.',
	'7.11.en': 'THE ENDLESS.',
	'7.11.nl': 'HET ONEINDIGE.',
};
