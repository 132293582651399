import * as PIXI from 'pixi.js';
import { ZoomBlurFilter } from 'pixi-filters';
import displacementImage from '../../assets/filter/displacement.png';

const displacementSprite = PIXI.Sprite.from(displacementImage);

let prevDeltaXY = [0, 0];
let prevMouseXY = [0, 0];

const distanceBetweenPoints = (pointA, pointB) => {
	const powX = Math.pow(pointA[0] - pointB[0], 2);
	const powY = Math.pow(pointA[1] - pointB[1], 2);
	return Math.sqrt(powX + powY);
};

export const triggerContinuousParallelFilter = (store) => {
	const startDisplacementScale = 10;
	const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite, startDisplacementScale);
	displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
	store.parallelsContainer.addChild(displacementSprite);

	const zoomBlurFilter = new ZoomBlurFilter(0, new PIXI.Point().set(0, 0), 0);

	let count = 0;

	const animationLoop = () => {
		const deltaXY = [
			(store.mouseXY[0] - prevMouseXY[0]) * 2,
			(store.mouseXY[1] - prevMouseXY[1]) * 2,
		];

		store.aggregateMouseVelocity += (distanceBetweenPoints(deltaXY, prevDeltaXY) - store.aggregateMouseVelocity) * 0.1;

		displacementFilter.scale.set(
			startDisplacementScale + store.aggregateMouseVelocity * 1500,
			startDisplacementScale + store.aggregateMouseVelocity * 1500,
		);
		displacementSprite.x = count + store.aggregateMouseVelocity * 50;

		zoomBlurFilter.strength = store.aggregateMouseVelocity * 0.5;
		zoomBlurFilter.center.set(
			store.activeParallelElement?.position.x + store.mouseXY[0] * store.activeParallelElement?.width,
			store.activeParallelElement?.position.y + store.mouseXY[1] * store.activeParallelElement?.height,
		);

		count += 1;

		prevDeltaXY = deltaXY;
		prevMouseXY = store.mouseXY;

		requestAnimationFrame(animationLoop);
	};

	store.parallelsContainer.filters = [displacementFilter, zoomBlurFilter];

	animationLoop();
};
