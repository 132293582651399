const content = [
	{
		class: 'content-name',
		en: 'Parallel<br/>Archaeologies',
		nl: 'parallelle<br/>archeologie&euml;n',
	},
	{
		class: 'content-subheader',
		en: 'An Internet Cosmology',
		nl: 'Een Internet-Kosmologie',
	},
	{
		class: 'content-story',
		en: 'the<br/>Story',
		nl: 'het<br/>Verhaal',
	},
	{
		class: 'content-story-body',
		en: '<p>Parallel Archaeologies is an ancient portal that has opened up at the bottom of a well, like a blackhole it sucks our attention inwards streaming distortions of alternate internets and code cracked dimensions as we fall into a cosmic spiraling binary bliss, that is, until the robotic hacker cats messed it all up.</p><p>It all started when The Gods assembled a select group of ancient avatars to decide upon the shape of a new, fantasy universe. But, once they got to work, they quickly realized that they couldnt agree! The mermaid wanted it square, the centaur wanted it cylindrical, and the reptilian wanted it mobius...</p><p>Frustrated by their inability to decide they all fell into a deep slumber... </p><p>While they slept, a clowder of hacker cats descend upon them to steel their avatar code and send it to an AI engine, essentially spoiling their plans. </p><p>When the ancients awoke they found themselves trapped inside of an artificial intelligence software in which their identities were consumed by data libraries. Stuck with only fragments of their eyes freaking out, the internet collapsed, sending the universe into an endless binary blackhole. </p><p>That is, until it restarts...</p>',
		nl: '<p>Parallel Archaeologies is een oud portaal dat zich op de bodem van een put opent. Via een zwart gat zuigt het onze aandacht op door stromende vervormingen van alternatief internet en gecodeerde dimensies. We vallen in een kosmische spiraalvormige binaire gelukzaligheid, dat wil zeggen, totdat de robot Hackerkatten hebben het allemaal verprutsen.</p><p>Het begon allemaal toen The Gods een selecte groep Oude Avatars verzamelde, om te beslissen over de vorm van een nieuw fantasie-universum. Maar toen ze eenmaal aan het werk waren, beseften ze al snel dat ze het er niet mee eens waren! De Zeemeermin wilde het internet vierkant hebben, de Centaur wilde het cilindrisch, en de Reptielachtige wilde het mobius...</p><p>Gefrustreerd door hun onvermogen om te beslissen vielen ze allemaal in een diepe slaap...</p><p>Terwijl ze sliepen, daalt een Clowder van Hacker-katten op hen af om hun Avatarcode te stelen en naar een AI-engine te sturen, waardoor hun plannen worden verpest.</p><p>Toen de Oude Avatars wakker werden, zaten ze vast in kunstmatige intelligentiesoftware waarin hun identiteit werd geconsumeerd door gegevensbibliotheken. Vastgelopen met alleen maar fragmenten van hun ogen die in paniek raakten, stortte het internet in en stuurt het universum de Oude Avatars in een eindeloos dubbel zwart gat.</p><p>Dat wil zeggen, totdat het opnieuw opstart...</p>',
	},
	{
		class: 'content-start',
		en: 'start',
		nl: 'start',
	},
	{
		class: 'content-loading',
		en: 'loading',
		nl: 'laden',
	},
	{
		class: 'content-languageChange',
		en: 'language changer',
		nl: 'taal veranderen',
	},
	{
		class: 'content-about',
		en: 'about',
		nl: 'over',
	},
	{
		class: 'content-aboutSummary',
		en: 'AN INTERNET COSMOLOGY CREATED BY',
		nl: 'een internet cosmologie gemaakt door',
	},
	{
		class: 'content-aboutProgrammers',
		en: 'LEAD PROGRAMMER DEITIES',
		nl: 'programmeur goden',
	},
	{
		class: 'content-aboutSound',
		en: 'SOUND',
		nl: 'geluid',
	},
	{
		class: 'content-aboutProducers',
		en: 'PRODUCERS',
		nl: 'producenten',
	},
	{
		class: 'content-aboutCuratedBy',
		en: 'CURATED BY',
		nl: 'gecureerd door',
	},
	{
		class: 'content-aboutCommisionedBy',
		en: 'COMMISIONED BY',
		nl: 'commisioned door',
	},
	{
		class: 'content-aboutSupportedBy',
		en: 'SUPPORTED BY',
		nl: 'mede mogelijk gemaakt door',
	},
	{
		class: 'content-aboutSupportedByName',
		en: '<a href="https://www.mondriaanfonds.nl/en/" target="__blank">MONDRIAAN FUND</a>',
		nl: '<a href="https://www.mondriaanfonds.nl/" target="__blank">MONDRIAAN FONDS</a>',
	},
	{
		class: 'content-close',
		en: '[close]',
		nl: '[sluit]',
	},
	{
		class: 'content-restart',
		en: 'restart',
		nl: 'herstart',
	},
	{
		class: 'content-autoPlay',
		en: 'auto<br/>play',
		nl: 'auto<br/>spelen',
	},
	{
		class: 'content-subtitle',
		en: 'sub<br/>titles',
		nl: 'onder<br/>titeling',
	},
];

export const setContentLanguages = (currentLanguage) => {
	content.forEach(contentType => {
		const contentTypeHTMLCollection = document.getElementsByClassName(contentType.class);

		[...Array(contentTypeHTMLCollection.length)].forEach((_, i) => {
			contentTypeHTMLCollection.item(i).innerHTML = contentType[currentLanguage];
		});
	});
};
