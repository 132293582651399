import * as PIXI from 'pixi.js';
import { TwistFilter, BulgePinchFilter, ReflectionFilter } from 'pixi-filters';
import { sample } from 'lodash';

const reflectionFilter = new ReflectionFilter({
	mirror: false,
	boundary: 0,
	amplitude: [20, 20],
	waveLength: [2, 2],
	alpha: [1, 1],
	time: 0,
});

const FILTER_TYPES = ['twist', 'bulge'];

let isAnimating = false;
let counter;
let counterMax;
let durationFrames;

export const resetStageFilters = (store) => {
	store.app.stage.filters = null;
};

let reflactionTimeout;

export const addReflectionFilter = (store) => {
	if (!store.hasStartedExperience) {
		return;
	}

	clearTimeout(reflactionTimeout);

	if (store.hasLoaderInTunnel) {
		reflactionTimeout = setTimeout(() => {
			store.app.stage.filters = [];
		}, 300);
	}

	if (!store.app.stage.filters) {
		store.app.stage.filters = [reflectionFilter];
		return;
	}

	store.app.stage.filters.push(reflectionFilter);
};

export const triggerParallelFilter = (store, halfwayCallback) => {
	if (isAnimating) {
		addReflectionFilter(store);
		return;
	}

	isAnimating = true;
	counter = 0;
	counterMax = Math.PI;
	durationFrames = 60;

	switch (sample(FILTER_TYPES)) {
		case 'twist':
			triggerTwistFilter(store, halfwayCallback);
			break;

		case 'bulge':
			triggerBulgeFilter(store, halfwayCallback);
			break;
	}
};

const animationLoop = (store, halfwayCallback, filterFuc) => {
	counter += 1 / (durationFrames / counterMax);
	filterFuc();

	if (counter >= counterMax / 2 && counter <= counterMax / 2 + 1 / (durationFrames / counterMax)) {
		halfwayCallback();
	}

	if (counter <= counterMax) {
		requestAnimationFrame(() => animationLoop(store, halfwayCallback, filterFuc));
	} else {
		isAnimating = false;
		resetStageFilters(store);
	}
};

const triggerTwistFilter = (store, halfwayCallback) => {
	const filter = new TwistFilter(0, 0, 0);
	filter.offset = new PIXI.Point().set(store.screenSize.width / 2, store.screenSize.height / 2);
	store.app.stage.filters = [filter];

	const direction = Math.random() < 0.5 ? -1 : 1;
	const screenSize = Math.min(store.screenSize.width, store.screenSize.height);
	const maxRadius = Math.max(Math.random() * screenSize, screenSize / 2);

	animationLoop(store, halfwayCallback, () => {
		filter.angle = Math.sin(counter) * 20 * direction;
		filter.radius = Math.sin(counter) * maxRadius;
	});
};

const triggerBulgeFilter = (store, halfwayCallback) => {
	const filter = new BulgePinchFilter({
		center: [0.5, 0.5], // The BulgePinchFilter's position is different from other elements [0.5, 0.5] is the middle here...
		radius: 0,
		strength: -1,
	});

	store.app.stage.filters = [filter];

	animationLoop(store, halfwayCallback, () => {
		filter.radius = Math.sin(counter) * 900;
	});
};
