export const triggerExplosionFilter = (store, onCompleteCallback) => {
	if (!store.activeParallelElement) {
		onCompleteCallback();
		return;
	}

	const explosionLayers = [...Array(4)].map((_, i) => ({
		sprite: store.explosionSprites[Math.round(Math.random()) < 0.5 ? 0 : 1],
		index: i,
	}));

	explosionLayers.forEach(({ sprite, index }) => {
		sprite.position.set(
			store.activeParallelElement.position.x + (Math.random() - 0.5) * 250,
			store.activeParallelElement.position.y + (Math.random() - 0.5) * 250,
		);
		sprite.width = store.activeParallelElement.width * 1.5;
		sprite.height = store.activeParallelElement.height * 1.5;
		sprite.loop = false;

		setTimeout(() => {
			sprite.gotoAndPlay(0);
		}, Math.random() * 100 + 10);

		store.parallelsContainer.addChildAt(sprite, store.parallelsContainer.children.length - 1);

		sprite.onComplete = () => {
			onCompleteCallback();
			store.parallelsContainer.removeChild(sprite);
		};
	});
};
