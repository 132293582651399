import * as scene01 from '../../assets/scene01/*.png';
const scene01Parallel01 = require('../../assets/scene01/parallel01/*.json');
const scene01Parallel02 = require('../../assets/scene01/parallel02/*.json');
const scene01Parallel03 = require('../../assets/scene01/parallel03/*.json');
const scene01Parallel04 = require('../../assets/scene01/parallel04/*.json');
const scene01Parallel05 = require('../../assets/scene01/parallel05/*.json');
const scene01Parallel06 = require('../../assets/scene01/parallel06/*.json');
const scene01Parallel07 = require('../../assets/scene01/parallel07/*.json');
const scene01Parallel08 = require('../../assets/scene01/parallel08/*.json');
const scene01Parallel09 = require('../../assets/scene01/parallel09/*.json');
const scene01Parallel10 = require('../../assets/scene01/parallel10/*.json');
const scene01Parallel11 = require('../../assets/scene01/parallel11/*.json');
const scene01Loader = require('../../assets/scene01/loader/*.json');

import * as scene02 from '../../assets/scene02/*.png';
const scene02Parallel01 = require('../../assets/scene02/parallel01/*.json');
const scene02Parallel02 = require('../../assets/scene02/parallel02/*.json');
const scene02Parallel03 = require('../../assets/scene02/parallel03/*.json');
const scene02Parallel04 = require('../../assets/scene02/parallel04/*.json');
const scene02Parallel05 = require('../../assets/scene02/parallel05/*.json');
const scene02Parallel06 = require('../../assets/scene02/parallel06/*.json');
const scene02Parallel07 = require('../../assets/scene02/parallel07/*.json');
const scene02Parallel08 = require('../../assets/scene02/parallel08/*.json');
const scene02Parallel09 = require('../../assets/scene02/parallel09/*.json');
const scene02Parallel10 = require('../../assets/scene02/parallel10/*.json');
const scene02Parallel11 = require('../../assets/scene02/parallel11/*.json');
const scene02Loader = require('../../assets/scene02/loader/*.json');

import * as scene03 from '../../assets/scene03/*.png';
const scene03Parallel01 = require('../../assets/scene03/parallel01/*.json');
const scene03Parallel02 = require('../../assets/scene03/parallel02/*.json');
const scene03Parallel03 = require('../../assets/scene03/parallel03/*.json');
const scene03Parallel04 = require('../../assets/scene03/parallel04/*.json');
const scene03Parallel05 = require('../../assets/scene03/parallel05/*.json');
const scene03Parallel06 = require('../../assets/scene03/parallel06/*.json');
const scene03Parallel07 = require('../../assets/scene03/parallel07/*.json');
const scene03Parallel08 = require('../../assets/scene03/parallel08/*.json');
const scene03Parallel09 = require('../../assets/scene03/parallel09/*.json');
const scene03Parallel10 = require('../../assets/scene03/parallel10/*.json');
const scene03Parallel11 = require('../../assets/scene03/parallel11/*.json');
const scene03Loader = require('../../assets/scene03/loader/*.json');

import * as scene04 from '../../assets/scene04/*.png';
const scene04Parallel01 = require('../../assets/scene04/parallel01/*.json');
const scene04Parallel02 = require('../../assets/scene04/parallel02/*.json');
const scene04Parallel03 = require('../../assets/scene04/parallel03/*.json');
const scene04Parallel04 = require('../../assets/scene04/parallel04/*.json');
const scene04Parallel05 = require('../../assets/scene04/parallel05/*.json');
const scene04Parallel06 = require('../../assets/scene04/parallel06/*.json');
const scene04Parallel07 = require('../../assets/scene04/parallel07/*.json');
const scene04Parallel08 = require('../../assets/scene04/parallel08/*.json');
const scene04Parallel09 = require('../../assets/scene04/parallel09/*.json');
const scene04Parallel10 = require('../../assets/scene04/parallel10/*.json');
const scene04Parallel11 = require('../../assets/scene04/parallel11/*.json');
const scene04Loader = require('../../assets/scene04/loader/*.json');

import * as scene05 from '../../assets/scene05/*.png';
const scene05Parallel01 = require('../../assets/scene05/parallel01/*.json');
const scene05Parallel02 = require('../../assets/scene05/parallel02/*.json');
const scene05Parallel03 = require('../../assets/scene05/parallel03/*.json');
const scene05Parallel04 = require('../../assets/scene05/parallel04/*.json');
const scene05Parallel05 = require('../../assets/scene05/parallel05/*.json');
const scene05Parallel06 = require('../../assets/scene05/parallel06/*.json');
const scene05Parallel07 = require('../../assets/scene05/parallel07/*.json');
const scene05Parallel08 = require('../../assets/scene05/parallel08/*.json');
const scene05Parallel09 = require('../../assets/scene05/parallel09/*.json');
const scene05Parallel10 = require('../../assets/scene05/parallel10/*.json');
const scene05Parallel11 = require('../../assets/scene05/parallel11/*.json');
const scene05Loader = require('../../assets/scene05/loader/*.json');

import * as scene06 from '../../assets/scene06/*.png';
const scene06Parallel01 = require('../../assets/scene06/parallel01/*.json');
const scene06Parallel02 = require('../../assets/scene06/parallel02/*.json');
const scene06Parallel03 = require('../../assets/scene06/parallel03/*.json');
const scene06Parallel04 = require('../../assets/scene06/parallel04/*.json');
const scene06Parallel05 = require('../../assets/scene06/parallel05/*.json');
const scene06Parallel06 = require('../../assets/scene06/parallel06/*.json');
const scene06Parallel07 = require('../../assets/scene06/parallel07/*.json');
const scene06Parallel08 = require('../../assets/scene06/parallel08/*.json');
const scene06Parallel09 = require('../../assets/scene06/parallel09/*.json');
const scene06Parallel10 = require('../../assets/scene06/parallel10/*.json');
const scene06Parallel11 = require('../../assets/scene06/parallel11/*.json');
const scene06Loader = require('../../assets/scene06/loader/*.json');

import * as scene07 from '../../assets/scene07/*.png';

const makeString = (returnedObject) => Object.values(returnedObject).filter(value => typeof value === 'string');

const assetsList = [
	{
		scene: makeString(scene01),
		parallels: [
			makeString(scene01Parallel01),
			makeString(scene01Parallel02),
			makeString(scene01Parallel03),
			makeString(scene01Parallel04),
			makeString(scene01Parallel05),
			makeString(scene01Parallel06),
			makeString(scene01Parallel07),
			makeString(scene01Parallel08),
			makeString(scene01Parallel09),
			makeString(scene01Parallel10),
			makeString(scene01Parallel11),
		],
		loader: makeString(scene01Loader),
	},
	{
		scene: makeString(scene02),
		parallels: [
			makeString(scene02Parallel01),
			makeString(scene02Parallel02),
			makeString(scene02Parallel03),
			makeString(scene02Parallel04),
			makeString(scene02Parallel05),
			makeString(scene02Parallel06),
			makeString(scene02Parallel07),
			makeString(scene02Parallel08),
			makeString(scene02Parallel09),
			makeString(scene02Parallel10),
			makeString(scene02Parallel11),
		],
		loader: makeString(scene02Loader),
	},
	{
		scene: makeString(scene03),
		parallels: [
			makeString(scene03Parallel01),
			makeString(scene03Parallel02),
			makeString(scene03Parallel03),
			makeString(scene03Parallel04),
			makeString(scene03Parallel05),
			makeString(scene03Parallel06),
			makeString(scene03Parallel07),
			makeString(scene03Parallel08),
			makeString(scene03Parallel09),
			makeString(scene03Parallel10),
			makeString(scene03Parallel11),
		],
		loader: makeString(scene03Loader),
	},
	{
		scene: makeString(scene04),
		parallels: [
			makeString(scene04Parallel01),
			makeString(scene04Parallel02),
			makeString(scene04Parallel03),
			makeString(scene04Parallel04),
			makeString(scene04Parallel05),
			makeString(scene04Parallel06),
			makeString(scene04Parallel07),
			makeString(scene04Parallel08),
			makeString(scene04Parallel09),
			makeString(scene04Parallel10),
			makeString(scene04Parallel11),
		],
		loader: makeString(scene04Loader),
	},
	{
		scene: makeString(scene05),
		parallels: [
			makeString(scene05Parallel01),
			makeString(scene05Parallel02),
			makeString(scene05Parallel03),
			makeString(scene05Parallel04),
			makeString(scene05Parallel05),
			makeString(scene05Parallel06),
			makeString(scene05Parallel07),
			makeString(scene05Parallel08),
			makeString(scene05Parallel09),
			makeString(scene05Parallel10),
			makeString(scene05Parallel11),
		],
		loader: makeString(scene05Loader),
	},
	{
		scene: makeString(scene06),
		parallels: [
			makeString(scene06Parallel01),
			makeString(scene06Parallel02),
			makeString(scene06Parallel03),
			makeString(scene06Parallel04),
			makeString(scene06Parallel05),
			makeString(scene06Parallel06),
			makeString(scene06Parallel07),
			makeString(scene06Parallel08),
			makeString(scene06Parallel09),
			makeString(scene06Parallel10),
			makeString(scene06Parallel11),
		],
		loader: makeString(scene06Loader),
	},
	{
		scene: makeString(scene07),
		parallels: [],
		loader: [],
	},
];

export const allAssets = assetsList.map(({ scene, parallels, loader }) => ({
	scene,
	parallels: parallels.flat(),
	loader,
}));

const explosionsImport = require('../../assets/explosions/*.json');
export const explosions = makeString(explosionsImport);

import wellTexture from '../../assets/UI/well/*.png';
export const wellUI = makeString(wellTexture);

import parallelSwitchMP3 from '../../assets/sound/parallel-switch.mp3';
export const parallelSwitch = parallelSwitchMP3;

import soundtrackMP3 from '../../assets/sound/soundtrack.mp3';
export const soundtrack = soundtrackMP3;

import * as allSounds from '../../assets/**/*.mp3';

export const loaderSounds = Object.entries(allSounds)
	.filter(([key]) => key.startsWith('scene'))
	.map(([, value]) =>
		value.loader?.loader
	);

export const sceneSounds = Object.entries(allSounds)
	.filter(([key]) => key.startsWith('scene'))
	.map(([, value]) =>
		Object.entries(value)
			.filter(([key]) => key.startsWith('parallel'))
			.map(([, value]) => value)
	);

import * as bg from '../../assets/bg/*.json';
export const background = makeString(bg);
