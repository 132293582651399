import { triggerContinuousParallelFilter } from './triggerContinuousParallelFilter';

export const initialLoadEnd = (store) => {
	clearInterval(store.loaderFileNameInterval);

	if (store.isInstallationRun) {
		store.app.stage.addChild(store.tunnelContainer);
		store.app.stage.addChild(store.parallelsContainer);
		triggerContinuousParallelFilter(store);

		return;
	}

	document.getElementById('loading-experience').classList.add('is-hidden');

	setTimeout(() => {
		document.getElementById('loading-experience').classList.add('is-gone');
	}, 400);

	setTimeout(() => {
		document.getElementById('landing-overlay').classList.remove('is-hidden');
		document.getElementById('landing-overlay').classList.remove('is-gone');

		// Add the well- and tunnelcontainer after background for correct order
		// As a bonus the well will not show before the background is loaded
		store.app.stage.addChild(store.tunnelContainer);
		store.app.stage.addChild(store.parallelsContainer);
		store.app.stage.addChild(store.wellContainer);
		triggerContinuousParallelFilter(store);
	}, 500);
};
