import { debounce } from 'lodash';

export const updateTunnelLayerPositions = debounce((store) => {
	const spriteScene = store[`scene${store.sceneIndex}`];

	store.tunnelLayers = store.tunnelLayers.map(layer => {
		const startPosition = [
			store.screenSize.width / 2 + Math.random() * spriteScene.randomDisplaceEnvOnStart,
			store.screenSize.height / 2 + Math.random() * spriteScene.randomDisplaceEnvOnStart,
		];

		return {
			...layer,
			startPosition,
		};
	});
}, 300);
